export const ROOT_URL = process.env.REACT_APP_ROOT_URL as string;
export const GA4_ID = process.env.REACT_APP_GA4_ID as string;
export const SELECT_ROWS_PER_PAGE = [30, 60, 90];
export const DEFAULT_LIMIT_PARAM = 30;
export const DEFAULT_OFFSET_PARAM = 0;
export const DEFAULT_PAGE_PARAM = 1;
export const DEFAULT_RSS_ID_PARAM = 0;
export const SCREEN_SIZE_VISIBLE_XS = { display: { xs: "block", sm: "none" } };
export const SCREEN_SIZE_VISIBLE_XS_FLEX = { display: { xs: "flex", sm: "none" } };
export const SCREEN_SIZE_HIDDEN_XS = { display: { xs: "none", sm: "block" } };
export const SCREEN_SIZE_HIDDEN_XS_FLEX = { display: { xs: "none", sm: "flex" } };
export const SITE_NAME = "イロイロまとめアンテナ";
export const TAG_DESCRIPTION_DEFAULT = "話題の情報を発信する2ch,5chまとめ系アンテナサイト";
export const TAG_OG_TYPE_TOP = "website";
export const TAG_OG_TYPE_CONTENT = "article";
export const TAG_OG_IMG_DEFAULT = `${ROOT_URL}/site-image.png`;
export const TAG_KEYWORDS_DEFAULT = ["まとめ", "アンテナ", "2ch", "5ch"];
export const PAGE_ABOUT = {
    path: "/about",
    name: "サイトについて",
    description: "本サイトは2chアンテナサイトです。お気軽にサイトの登録依頼お願いします。",
};
export const PAGE_NOT_FOUND = {
    path: "/404",
    name: "404 NOT FOUND",
    description: "お探しのページは見つかりませんでした。",
};
export const PAGE_REGISTER = {
    path: "/register",
    name: "登録依頼",
    description: "まとめブログの登録依頼はこのページからお願いします。",
};
export const COLOR_HIGHLIGHT_BG = "#ffe1e1";
export const COLOR_HIGHLIGHT_BG_HOVER = "#fbc2c2";
export const COLOR_HIGHLIGHT_NORMAL_CELL_BG_HOVER = "#ededed";
export const COLOR_HIGHLIGHT_FONT = "red";
export const COLOR_BTN_SUBMIT = "#202020";
export const FORM_REGISTER_CATEGORY = ["ニュース", "アニメ", "漫画", "ゲーム", "スポーツ", "生活", "芸能", "その他"];
export const API_PATH_SEND_MAIL_REGISTER = "/api/send/register";
