import { memo } from 'react';
import { Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { SITE_NAME } from '../../config';

const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem",
  }
}));

export const Copyright = memo(() => {
  return (
    <StyledTypography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/">{SITE_NAME}</Link>
      {" "}{new Date().getFullYear()}{"."}
    </StyledTypography>
  );
});
