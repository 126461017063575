import { memo } from "react";

export const GoogleAds = memo(() => {
  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", textAlign: "center" }}
      data-ad-client="ca-pub-5925083465505736"
      data-ad-slot="2689717563"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
});
