import { ReactNode, memo } from "react";
import { usePageTracking } from "../../utils/tracking";

interface Props {
  children?: ReactNode;
}

export const Tracking = memo((props: Props) => {
  const { children } = props;
  usePageTracking();

  return <>{children}</>;
});
