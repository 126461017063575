import { ReactNode, memo } from "react";
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Typography,
  Container,
  Box,
  styled,
} from "@mui/material";

import { Header } from "../header";
import { Copyright } from "../copyright";

interface Props {
  children?: ReactNode;
  title?: string;
}

const theme = createTheme({
  typography: {
    button: {
        textTransform: "none"
    }
  },
  palette: {
    primary: {
      light: '#2e2e2e',
      main: '#0a0a0a',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
  }
});

const StyledMain = styled("main")(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    paddingBottom: theme.spacing(6),
  }
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: theme.spacing(8),
  }
}));

export const Template = memo((props: Props) => {
  const { children, title } = props;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <StyledMain>
        <StyledContainer maxWidth="lg">
          {title && (
            <Typography component="h2" variant="h5" color="inherit">
              {title}
            </Typography>
          )}
          {children}
          <Box pt={4}>
            <Copyright />
          </Box>
        </StyledContainer>
      </StyledMain>
    </ThemeProvider>
  );
});
