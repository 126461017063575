import { memo, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  Button,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import WebIcon from "@mui/icons-material/Web";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

import {
  SCREEN_SIZE_HIDDEN_XS,
  SCREEN_SIZE_HIDDEN_XS_FLEX,
  SCREEN_SIZE_VISIBLE_XS,
  SITE_NAME,
} from "../../config";

const MENU_LINKS = [
  {
    path: "/register",
    name: "登録依頼",
  },
  {
    path: "/about",
    name: "サイトについて",
  },
];

const MENU_ICONS = (index: number) => {
  switch (index) {
    case 0:
      return <AppRegistrationIcon />;
    case 1:
      return <WebIcon />;
    default:
      return <WebIcon />;
  }
};

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.secondary,
}));

const StyledLinkTop = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.contrastText,
  [theme.breakpoints.down("sm")]: {
    flexGrow: 1,
    display: "flex",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: 46,
}));

const StyledList = styled(List)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingTop: 7,
    paddingBottom: 7,
  },
}));

export const Header = memo(() => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <StyledToolbar>
          <StyledLinkTop to="/">
            <Box
              component="img"
              sx={{
                maxHeight: { xs: 20, sm: 30 },
                mr: 3,
              }}
              alt={SITE_NAME}
              src="/logo.svg"
            />
          </StyledLinkTop>
          <Box sx={{ flexGrow: 1, ...SCREEN_SIZE_HIDDEN_XS_FLEX }}>
            {MENU_LINKS.map((item) => (
              <StyledLinkTop key={item.name} to={item.path}>
                <Button
                  sx={{
                    my: 2,
                    mr: 1,
                    ml: 1,
                    color: "inherit",
                    fontWeight: "bold",
                    ...SCREEN_SIZE_HIDDEN_XS,
                  }}
                >
                  {item.name}
                </Button>
              </StyledLinkTop>
            ))}
          </Box>
          <Box sx={SCREEN_SIZE_VISIBLE_XS}>
            <IconButton
              size="small"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
              sx={{ ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              size="small"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
              sx={{ ...(!open && { display: "none" }) }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </StyledToolbar>
      </AppBar>
      <SwipeableDrawer
        open={open}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerOpen}
        anchor="right"
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 240, boxSizing: "border-box" },
        }}
      >
        <StyledToolbar />
        <StyledList>
          {MENU_LINKS.map((item, index) => (
            <StyledLink key={item.name} to={item.path}>
              <ListItem disablePadding>
                <StyledListItemButton>
                  <ListItemIcon>{MENU_ICONS(index)}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </StyledListItemButton>
              </ListItem>
            </StyledLink>
          ))}
        </StyledList>
      </SwipeableDrawer>
    </>
  );
});
