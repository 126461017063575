import { EntityState, createEntityAdapter } from '@reduxjs/toolkit';
import { Rss } from '../../../models';

export const featureKey = 'rss';

export interface RssState extends EntityState<Rss> {
  isFetching: boolean;
}

export const rssAdapter = createEntityAdapter<Rss>();

export const initialRssState: RssState = rssAdapter.getInitialState({
  isFetching: false,
});
