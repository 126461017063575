import { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Home, About, Register, NotFound } from "./pages";
import { Tracking } from "./shared";
import { Loader } from "./shared/components/loader";

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Tracking>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/register" element={<Register />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate replace to="/404"/>} />
          </Routes>
        </Tracking>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
