import { configureStore, combineReducers } from '@reduxjs/toolkit';
import * as rss from './rss';

const reducer = combineReducers({
  [rss.featureKey]: rss.reducer,
});

export const store = configureStore({
  reducer,
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
