import { createAsyncThunk } from '@reduxjs/toolkit';
import { rssService } from '../../../services';
import { featureKey } from '../states';

export const fetchAllRss = createAsyncThunk(
  `${featureKey}/fetchAll`,
  async (arg: { urls: string[] }, { rejectWithValue }) => {
    const { urls } = arg;
    const result = await rssService.fetchAll(urls);
    if (result) {
      return { rss: result };
    } else {
      return rejectWithValue('');
    }
  }
);

export const fetchRss = createAsyncThunk(
  `${featureKey}/fetch`,
  async (arg: { url: string }, { rejectWithValue }) => {
    const { url } = arg;
    const result = await rssService.fetch(url);
    if (result) {
      return { rss: result };
    } else {
      return rejectWithValue('');
    }
  }
);
