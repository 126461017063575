import { createSelector } from '@reduxjs/toolkit';
import { RssState, rssAdapter, featureKey } from '../states';

export interface RootState {
  [featureKey]: RssState;
}

// RSS情報を取得するSelector
const rssStateSelector = (state: RootState) => state[featureKey];

export const rssSelectors = rssAdapter.getSelectors(rssStateSelector);

export const isRssFetchingSelector = createSelector(rssStateSelector, (state) => state.isFetching);
