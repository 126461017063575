import { ReactNode, memo } from "react";
import { styled } from "@mui/material";

const StyledH3 = styled("h3")(({ theme }) => ({
  position: "relative",
  color: theme.palette.primary.contrastText,
  padding: "4px 0px 4px 40px",
  backgroundColor: theme.palette.primary.light,
  borderRadius: 40,
  "&::after": {
    position: "absolute",
    transform: "translateY(-50%)",
    content: "''",
    backgroundColor: theme.palette.primary.contrastText,
    width: 13,
    height: 13,
    left: 15,
    top: "50%",
    borderRadius: 10,
  },
}));

const StyledH4 = styled("h4")(({ theme }) => ({
  padding: 1,
  borderBottom: "1px solid",
  borderBottomColor: theme.palette.primary.light,
  color: theme.palette.text.primary,
}));

interface Props {
  children?: ReactNode;
}

export const HeadLine3 = memo((props: Props) => {
  const { children } = props;

  return (
    <StyledH3>
      {children}
    </StyledH3>
  );
});

export const HeadLine4 = memo((props: Props) => {
  const { children } = props;

  return (
    <StyledH4>
      {children}
    </StyledH4>
  );
});

