/**
 * date型変数を指定の文字列に変換
 * @param date Date型日付
 * @param format 変換文字列
 * @returns 指定日付文字列
 */
export const formatDate = (date: Date, format: string) => {
    format = format.replace(/yyyy/g, date.getFullYear().toString());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
    return format;
}

/**
 * 現在日基準で指定日のDate型データを取得
 * @param date 日付数値（マイナスで基準日より前）
 * @returns 指定日付
 */
export const getSpecifiedDate = (date: number) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + date);
    return currentDate;
}