import { Rss } from '../../models';
import { axios } from '../../shared/lib';

export class RssService {
  async fetchAll(urls: string[]): Promise<Rss[] | null> {
    const promises = urls.map(url => axios.get(url));

    const result = await Promise.all(promises)
      .then(response => response.map(r => r.data))
      .catch(error => null);
    
    if (result) {
      console.info("[RssService] fetchAll success. result=", result);
      return result!.reduce((acc, elem) => acc.concat(elem));
    } else {
      console.info("[RssService] fetchAll failed.");
      return null;
    }
  }

  fetch(url: string): Promise<Rss[]> {
    return axios.get(url)
    .then(response => response.data)
    .catch(error => '');
  }
}
