import { RegisterDto } from "../../models";
import { API_PATH_SEND_MAIL_REGISTER } from "../../shared/config";
import { axios } from "../../shared/lib";

export class SendMailService {
  async sendMailRegister(data: RegisterDto): Promise<{ isSuccess: boolean, result: any }> {
    try {
      const response = await axios.post(API_PATH_SEND_MAIL_REGISTER, data);
      return {
        isSuccess: true,
        result: response.data,
      };
    } catch (error) {
      return {
        isSuccess: false,
        result: error,
      };
    }
  }
}
