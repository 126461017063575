import { createReducer } from '@reduxjs/toolkit';
import { initialRssState, rssAdapter } from '../states';
import { fetchAllRss, fetchRss } from '../actions';

export const reducer = createReducer(initialRssState, (builder) =>
  builder
    .addCase(fetchAllRss.pending, (state) => {
      return { ...state, isFetching: true };
    })
    .addCase(fetchAllRss.fulfilled, (state, action) => {
      const { rss } = action.payload;
      return rssAdapter.upsertMany({ ...state, isFetching: false }, rss);
    })
    .addCase(fetchAllRss.rejected, (state) => {
      return { ...state, isFetching: false };
    })
    .addCase(fetchRss.pending, (state) => {
      return { ...state, isFetching: true };
    })
    .addCase(fetchRss.fulfilled, (state, action) => {
      const { rss } = action.payload;
      return rssAdapter.upsertMany({ ...state, isFetching: false }, rss);
    })
    .addCase(fetchRss.rejected, (state) => {
      return { ...state, isFetching: false };
    })
);
