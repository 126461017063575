import { memo } from "react";
import { Helmet } from "react-helmet-async";
import {
  SITE_NAME,
  ROOT_URL,
  TAG_DESCRIPTION_DEFAULT,
  TAG_OG_TYPE_TOP,
  TAG_OG_IMG_DEFAULT,
  TAG_KEYWORDS_DEFAULT
} from "../../config";

interface Props {
  title?: string;
  description?: string;
  path?: string;
  type?: string;
  imgPath?: string;
  keyWords?: string[];
}

export const HeadTag = memo((props: Props) => {
  const { title, description, path, type, imgPath, keyWords } = props;
  return (
    <Helmet>
      <title>{title ?? SITE_NAME}</title>
      <meta name="description" content={description ?? TAG_DESCRIPTION_DEFAULT} />
      <link rel="canonical" href={`${ROOT_URL}${path ?? ""}`} />
      <meta property="og:title" content={title ?? SITE_NAME} />
      <meta property="og:description" content={description ?? TAG_DESCRIPTION_DEFAULT} />
      <meta property="og:type" content={type ?? TAG_OG_TYPE_TOP} />
      <meta property="og:url" content={`${ROOT_URL}${path ?? ""}`} />
      <meta property="og:image" content={imgPath ?? TAG_OG_IMG_DEFAULT} />
      <meta itemProp="name" content={title ?? SITE_NAME} />
      <meta itemProp="description" content={description ?? TAG_DESCRIPTION_DEFAULT} />
      <meta itemProp="image" content={imgPath ?? TAG_OG_IMG_DEFAULT} />
      <meta itemProp="keywords" content={ keyWords?.join(",") ?? TAG_KEYWORDS_DEFAULT.join(",") } />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={imgPath ?? TAG_OG_IMG_DEFAULT} />
      <meta name="twitter:title" content={title ?? SITE_NAME} />
      <meta name="twitter:description" content={description ?? TAG_DESCRIPTION_DEFAULT} />
    </Helmet>
  );
});
