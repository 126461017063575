
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { GA4_ID } from "../../config";

export const usePageTracking = () => {
  const location = useLocation();

  // [info] 初回レンダリング時にGA4測定IDを設定する
  useEffect(() => {
    console.info("[usePageTracking] First Loading. Set GA4 ID.")
    ReactGA.initialize(GA4_ID);
  }, []);

  // [info] パス変更時にGA4にデータ送信
  useEffect(() => {
    console.info("[usePageTracking] Call ReacGA send. page=", location.pathname + location.search)
    console.info("[usePageTracking] title=", document.title)
    // [info] react-helmetで設定したtitleタグ情報はレンダリング直後には反映されない
    //        そのためsetTimeoutを使用してhelmetの処理終了を待つ
    window.setTimeout(() => {
      console.log("[usePageTracking] title=", document.title, "after setTimeout.");
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
        title: document.title,
      });
    });
  }, [location]);
};
